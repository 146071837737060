// Component quảng cáo
import { useEffect } from 'react';

const GoogleAdsHeaderPremium = () => {
  useEffect(() => {
    if (window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(function () {
        window.googletag.display('div-gpt-ad-1731913684734-0');
      });
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '300px',
          minHeight: '90px',
      }}
      className='google-ads'
    >
      <div
        id="div-gpt-ad-1731913684734-0"
        style={{
          minWidth: '300px',
          minHeight: '90px',
        }}
      ></div>
    </div>
  );
};

export default GoogleAdsHeaderPremium;
